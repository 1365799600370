<template>
  <div class="edit-div">
    <div class="form-container" v-if="!agency">
      <p class="form-title">{{ $t("label.administratorAccount") }}</p>
      <div class="form-element">
        <span v-if="contractInfo.version">Version du contrat : <strong>{{contractInfo.version}}</strong></span>
        <span v-if="contractInfo.lang">Langue du contrat : <strong>{{contractInfo.lang}}</strong></span>
      </div>
      <div class="form-element">
        <label for="name">{{ $t("label.name") }}</label>
        <input
          type="text"
          id="name"
          v-model="name"
          required
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="surname">{{ $t("label.surname") }}</label>
        <input
          type="text"
          id="surname"
          v-model="surname"
          required
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="email">{{ $t("label.email") }}</label>
        <input
          type="email"
          id="email"
          v-model="email"
          required
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="emailConfirmation">{{
          $t("label.emailConfirmation")
        }}</label>
        <input
          type="email"
          id="emailConfirmation"
          v-model="emailConfirmation"
          required
          :disabled="TAValidation"
        />
      </div>
      <div class="error-message" v-if="emailCheck != ''">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
          />
        </svg>
        <span>{{ emailCheck }}</span>
      </div>
      <div class="form-element">
        <span class="label">{{ $t("label.gender") }}</span>
        <ul class="form-list">
          <li>
            <input
              type="radio"
              id="male"
              v-model="gender"
              value="male"
              checked="checked"
              :disabled="TAValidation"
            />
            <label for="male">{{ $t("label.male") }}</label>
          </li>
          <li>
            <input
              type="radio"
              id="female"
              v-model="gender"
              value="female"
              :disabled="TAValidation"
            />
            <label for="female">{{ $t("label.female") }}</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-container" v-if="!agency">
      <p class="form-title">{{ $t("label.agencyInformation") }}</p>
      <div class="form-element">
        <label for="agencyName">{{ $t("label.agencyName") }}</label>
        <input
          type="text"
          id="agencyName"
          v-model="agencyName"
          required
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="agencyEmail">{{ $t("label.agencyEmail") }}</label>
        <input
          type="email"
          id="agencyEmail"
          v-model="agencyEmail"
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="emergencyNumber">{{ $t("label.emergencyNumber") }}</label>
        <input
          type="email"
          id="emergencyNumber"
          v-model="emergencyNumber"
          :disabled="TAValidation"
        />
      </div>
    </div>
    <div class="form-container">
      <p class="form-title">
        {{ $t("label.contractInformation") }}
      </p>
      <div class="form-element">
        <label for="society">{{ $t("label.contractForm.society") }}</label>
        <input
          type="text"
          id="society"
          v-model="contractInfo.society"
          :disabled="TAValidation"
        />
      </div>
      <div class="double-form-element">
        <div>
          <label for="societyModel">{{
            $t("label.contractForm.statut")
          }}</label>
          <input
            type="text"
            id="societyModel"
            v-model="contractInfo.societyModel"
            :disabled="TAValidation"
          />
        </div>
        <div>
          <label for="societyCapital">{{
            $t("label.contractForm.capital")
          }}</label>
          <input
            type="number"
            id="societyCapital"
            min="0"
            v-model="contractInfo.societyCapital"
            :disabled="TAValidation"
          />
        </div>
      </div>
      <div class="form-element">
        <label for="societyAddress">{{
          $t("label.contractForm.address")
        }}</label>
        <input
          type="text"
          id="societyAddress"
          v-model="contractInfo.societyAddress"
          :disabled="TAValidation"
        />
      </div>
      <div class="form-element">
        <label for="societyAddress2"
          >{{ $t("label.contractForm.address") }} (2)</label
        >
        <input
          type="text"
          id="societyAddress2"
          v-model="contractInfo.societyAddress2"
          :disabled="TAValidation"
        />
      </div>
      <div class="double-form-element">
        <div>
          <label for="societyZIP">{{ $t("label.contractForm.zip") }}</label>
          <input
            type="text"
            id="societyZIP"
            v-model="contractInfo.societyZIP"
            :disabled="TAValidation"
          />
        </div>
        <div>
          <label for="societyCity">{{ $t("label.contractForm.city") }}</label>
          <input
            type="text"
            id="societyCity"
            v-model="contractInfo.societyCity"
            :disabled="TAValidation"
          />
        </div>
      </div>
      <div class="double-form-element">
        <div>
          <label for="societyRegisterPlace">{{
            $t("label.contractForm.registerPlace")
          }}</label>
          <input
            type="text"
            id="societyRegisterPlace"
            v-model="contractInfo.societyRegisterPlace"
            :disabled="TAValidation"
          />
        </div>
        <div>
          <label for="societyRegisterId">{{
            $t("label.contractForm.registerId")
          }}</label>
          <input
            type="text"
            id="societyRegisterId"
            v-model="contractInfo.societyRegisterID"
            :disabled="TAValidation"
          />
        </div>
      </div>
      <div class="double-form-element">
        <div>
          <label for="country-list">{{
            $t("label.contractForm.country")
          }}</label>
          <input
            type="text"
            id="country-list"
            class="list"
            v-model="contractInfo.country"
            :disabled="TAValidation"
          />
        </div>
        <div>
          <label for="license">{{ $t("label.contractForm.licence") }}</label>
          <input
            type="text"
            id="societyRegisterId"
            v-model="contractInfo.licence"
            :disabled="TAValidation"
          />
        </div>
      </div>
      <div class="double-form-element">
        <div>
          <label for="tvaNumber">{{
            $t("label.contractForm.tvaNumber")
          }}</label>
          <input
            type="text"
            id="tvaNumber"
            v-model="contractInfo.tvaNumber"
            :disabled="TAValidation"
          />
        </div>
        <div>
          <label for="societyRepresentative">{{
            $t("label.contractForm.representative")
          }}</label>
          <input
            type="text"
            id="societyRepresentative"
            v-model="contractInfo.societyRepresentative"
            :disabled="TAValidation"
          />
        </div>
      </div>
      <div class="free-travel" v-if="agency">
        <input
          type="checkbox"
          id="free-first-travel"
          v-model="freeFirstTravel"
        />
        <label for="free-first-travel"
          >Forcer l'ajout d'un voyage gratuit</label
        >
      </div>
    </div>
    <div class="offers">
      <div class="offer">
        <div>
          <input
            type="checkbox"
            id="applicationFee"
            v-model="contractInfo.offers.applicationFee.state"
            :disabled="TAValidation"
          />
          <label for="applicationFee">Frais de dossiers</label>
        </div>
        <div>
          <p>
            Nouveau montant des frais de dossiers : <br /><input
              id="applicationFeeInput"
              type="number"
              min="0"
              max="99"
              v-model="contractInfo.offers.applicationFee.price"
              :disabled="TAValidation"
            />
            €
          </p>
        </div>
      </div>
      <div class="offer">
        <div>
          <input
            type="checkbox"
            id="cancelSubscriptionTravel"
            v-model="contractInfo.offers.cancelSubscriptionTravel.state"
            :disabled="TAValidation"
          />
          <label for="cancelSubscriptionTravel"
            >Annulation abonnement nombre voyages</label
          >
        </div>
        <div>
          <p>
            Annulation des frais d'abonnement à partir de
            <input
              id="cancelSubscriptionTravelInput"
              type="number"
              min="0"
              v-model="contractInfo.offers.cancelSubscriptionTravel.number"
              :disabled="TAValidation"
            />
            voyage dans le même mois
          </p>
        </div>
      </div>
      <div class="offer">
        <div>
          <input
            type="checkbox"
            id="cancelSubscription"
            v-model="contractInfo.offers.cancelSubscription.state"
            :disabled="TAValidation"
          />
          <label for="cancelSubscription">Annulation abonnement date</label>
        </div>
        <div>
          <p>
            Annulation des frais d'abonnement jusqu'au
            <input
              id="cancelSubscriptionInput"
              type="date"
              v-model="contractInfo.offers.cancelSubscription.date"
              :disabled="TAValidation"
            />
          </p>
        </div>
      </div>
      <div class="offer">
        <div>
          <input
            type="checkbox"
            id="cancelSubscriptionMonth"
            v-model="contractInfo.offers.cancelSubscriptionMonth.state"
            :disabled="TAValidation"
          />
          <label for="cancelSubscription">Mois d'abonement offerts :</label>
        </div>
        <div>
          <p>
            Annulation des frais d'abonnement pendant
            <input
              id="cancelSubscriptionInput"
              type="number"
              v-model="contractInfo.offers.cancelSubscriptionMonth.month"
              :disabled="TAValidation"
            />
            mois.
          </p>
        </div>
      </div>
    </div>
    <p class="TAValidation" v-if="TAValidation">
      Agence déjà validé. En attente de la validation du contrat par
      l'entreprise. Lien contrat :
    </p>
    <p class="TAValidation" v-if="TAValidation">https://app.geniustravel.io.io/#/contract/{{this.$route.params.id + "?pass=" + this.contractPassword + "&from=" + this.email}}</p>
    <button
      id="confirmation-button"
      @click="submitEdit"
      :disabled="checkForm && !TAValidation"
      class="save-button"
      v-if="!TAValidation && !agency"
    >
      Validate
    </button>
    <button
      id="confirmation-button agency"
      @click="submitEditAgency"
      :disabled="checkForm"
      class="save-button"
      v-if="!TAValidation && agency"
    >
      Validate
    </button>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "PreAgencyForm",
  data() {
    return {
      agency: false,
      freeFirstTravel: false,
      name: "",
      surname: "",
      email: "",
      agencyName: "",
      agencyEmail: "",
      gender: "male",
      emergencyNumber: "",
      emailConfirmation: "",
      TAValidation: "",
      contractPassword: "",
      contractInfo: {
        contractDate: "",
        version: "",
        society: "",
        societyModel: "",
        societyCapital: "",
        societyAddress: "",
        societyAddress2: "",
        societyZIP: "",
        societyCity: "",
        societyRegisterPlace: "",
        societyRegisterID: "",
        societyRepresentative: "",
        country: "",
        licence: "",
        tvaNumber: "",
        lang: "fr",
        offers: {
          applicationFee: {
            state: false,
            price: 99,
          },
          cancelSubscriptionTravel: {
            state: false,
            number: 0,
          },
          cancelSubscription: {
            state: false,
            date: Date.now(),
          },
          cancelSubscriptionMonth: {
            state: false,
            month: 0,
          },
        },
        webValidation: {
          state: false,
        },
        mailValidation: {
          state: false,
        },
      },
    };
  },
  computed: {
    checkForm: function () {
      if (!this.agency) {
        if (!this.name) {
          return true;
        }
        if (!this.surname) {
          return true;
        }
        if (!this.email) {
          return true;
        }
        if (!this.emergencyNumber) {
          return true;
        }
        if (!this.agencyName) {
          return true;
        }
        if (!this.agencyEmail) {
          return true;
        }
      }
      if (!this.contractInfo.society) {
        return true;
      }
      if (!this.contractInfo.societyModel) {
        return true;
      }
      if (!this.contractInfo.societyCapital) {
        return true;
      }
      if (!this.contractInfo.societyAddress) {
        return true;
      }
      if (!this.contractInfo.societyZIP) {
        return true;
      }
      if (!this.contractInfo.societyCity) {
        return true;
      }
      if (!this.contractInfo.societyRepresentative) {
        return true;
      }

      return false;
    },
    emailCheck: function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email.toLowerCase())) {
        if (
          this.email != this.emailConfirmation &&
          this.emailConfirmation != ""
        ) {
          return this.$t("error.emailCheck");
        }
        return "";
      } else {
        if (this.email != "") {
          return this.$t("error.invalidEmail");
        } else {
          return "";
        }
      }
    },
  },
  methods: {
    submitEdit: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.saving"));

        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          agencyEmail: this.agencyEmail,
          agencyName: this.agencyName,
          gender: this.gender,
          emergencyNumber: this.emergencyNumber,
          acceptContract: this.acceptContract,
          askForOffers: this.askForOffers,
          TAValidation: true,
          contractInfo: {
            contractDate: this.contractInfo.contractDate,
            version: this.contractInfo.version,
            society: this.contractInfo.society,
            societyType: this.contractInfo.societyModel,
            capital: this.contractInfo.societyCapital,
            address: this.contractInfo.societyAddress,
            zipCode: this.contractInfo.societyZIP,
            city: this.contractInfo.societyCity,
            commercialRegisterLocation: this.contractInfo.societyRegisterPlace,
            commercialRegisterNumber: this.contractInfo.societyRegisterID,
            country: this.contractInfo.country,
            licence: this.contractInfo.licence,
            tvaNumber: this.contractInfo.tvaNumber,
            representative: this.contractInfo.societyRepresentative,
            lang: this.contractInfo.lang,
            offers: {
              applicationFee: {
                state: this.contractInfo.offers.applicationFee.state,
                price: this.contractInfo.offers.applicationFee.price,
              },
              cancelSubscriptionTravel: {
                state: this.contractInfo.offers.cancelSubscriptionTravel.state,
                number: this.contractInfo.offers.cancelSubscriptionTravel
                  .number,
              },
              cancelSubscription: {
                state: this.contractInfo.offers.cancelSubscription.state,
                date: this.contractInfo.offers.cancelSubscription.date,
              },
              cancelSubscriptionMonth: {
                state: this.contractInfo.offers.cancelSubscriptionMonth.state,
                month: this.contractInfo.offers.cancelSubscriptionMonth.month,
              },
            },
            webValidation: {
              state: this.contractInfo.webValidation.state,
            },
            mailValidation: {
              state: this.contractInfo.mailValidation.state,
            },
          },
        };
        if (this.contractInfo.societyAddress2) {
          newData.contractInfo.address2 = this.contractInfo.societyAddress2;
        }
        let res = await HandleApi.putPreAgency(this.$route.params.id, newData);
        if (res.status == 200) {
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.validated", {
              object: this.$t("notification.agency"),
            })
          );
          this.$router.push("/" + res.data);
        }
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err);
      }
    },
    submitEditAgency: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.saving"));

        let newData = {
          acceptContract: this.acceptContract,
          TAValidation: true,
          contractInfo: {
            informationFilled: true,
            contractDate: Date.now(),
            society: this.contractInfo.society,
            societyType: this.contractInfo.societyModel,
            capital: this.contractInfo.societyCapital,
            address: this.contractInfo.societyAddress,
            zipCode: this.contractInfo.societyZIP,
            city: this.contractInfo.societyCity,
            commercialRegisterLocation: this.contractInfo.societyRegisterPlace,
            commercialRegisterNumber: this.contractInfo.societyRegisterID,
            country: this.contractInfo.country,
            licence: this.contractInfo.licence,
            tvaNumber: this.contractInfo.tvaNumber,
            representative: this.contractInfo.societyRepresentative,
            lang: this.contractInfo.lang,
            offers: {
              applicationFee: {
                state: this.contractInfo.offers.applicationFee.state,
                price: this.contractInfo.offers.applicationFee.price,
              },
              cancelSubscriptionTravel: {
                state: this.contractInfo.offers.cancelSubscriptionTravel.state,
                number: this.contractInfo.offers.cancelSubscriptionTravel
                  .number,
              },
              cancelSubscription: {
                state: this.contractInfo.offers.cancelSubscription.state,
                date: this.contractInfo.offers.cancelSubscription.date,
              },
              cancelSubscriptionMonth: {
                state: this.contractInfo.offers.cancelSubscriptionMonth.state,
                month: this.contractInfo.offers.cancelSubscriptionMonth.month,
              },
            },
            webValidation: {
              state: this.contractInfo.webValidation.state,
            },
            mailValidation: {
              state: this.contractInfo.mailValidation.state,
            },
          },
        };
        if (this.contractInfo.societyAddress2) {
          newData.contractInfo.address2 = this.contractInfo.societyAddress2;
        }
        if (this.freeFirstTravel) {
          newData.freeFirstTravel = this.freeFirstTravel;
        }
        let res = await HandleApi.putAgencyContract(
          this.$route.params.id,
          "validation",
          newData
        );
        if (res.status == 200) {
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.validated", {
              object: this.$t("notification.agency"),
            })
          );
          this.$router.push("/" + res.data);
        }
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
  async created() {
    if (this.$route.query.agency) {
      this.agency = true;
      this.TAValidation = false;
    }
    if (!this.$route.query.agency) {
      try {
        let preAgency = await HandleApi.getPreAgencyInformation(
          this.$route.params.id
        );
        this.name = preAgency.name;
        this.surname = preAgency.surname;
        this.email = preAgency.email;
        this.agencyName = preAgency.agencyName;
        this.agencyEmail = preAgency.agencyEmail;
        this.emailConfirmation = preAgency.email;
        this.gender = preAgency.gender;
        this.emergencyNumber = preAgency.emergencyNumber;
        this.TAValidation = preAgency.TAValidation;
        this.contractPassword = preAgency.contractPassword;
        this.contractInfo.contractDate = preAgency.contractInfo.contractDate;
        this.contractInfo.version = preAgency.contractInfo.version;
        this.contractInfo.society = preAgency.contractInfo.society;
        this.contractInfo.societyModel = preAgency.contractInfo.societyType;
        this.contractInfo.societyCapital = preAgency.contractInfo.capital;
        this.contractInfo.societyAddress = preAgency.contractInfo.address;
        this.contractInfo.societyAddress2 = preAgency.contractInfo.address2;
        this.contractInfo.societyZIP = preAgency.contractInfo.zipCode;
        this.contractInfo.societyCity = preAgency.contractInfo.city;
        this.contractInfo.societyRegisterPlace =
          preAgency.contractInfo.commercialRegisterLocation;
        this.contractInfo.societyRegisterID =
          preAgency.contractInfo.commercialRegisterNumber;
        this.contractInfo.country = preAgency.contractInfo.country;
        this.contractInfo.licence = preAgency.contractInfo.licence;
        this.contractInfo.tvaNumber = preAgency.contractInfo.tvaNumber;
        this.contractInfo.societyRepresentative =
          preAgency.contractInfo.representative;
        this.contractInfo.offers.applicationFee.state =
          preAgency.contractInfo.offers.applicationFee.state;
        this.contractInfo.offers.applicationFee.price =
          preAgency.contractInfo.offers.applicationFee.price;
        this.contractInfo.offers.cancelSubscriptionTravel.state =
          preAgency.contractInfo.offers.cancelSubscriptionTravel.state;
        this.contractInfo.offers.cancelSubscriptionTravel.number =
          preAgency.contractInfo.offers.cancelSubscriptionTravel.number;
        this.contractInfo.offers.cancelSubscription.state =
          preAgency.contractInfo.offers.cancelSubscription.state;
        this.contractInfo.offers.cancelSubscription.date = new Date(
          preAgency.contractInfo.offers.cancelSubscription.date
        )
          .toISOString()
          .substr(0, 10);
        this.contractInfo.offers.cancelSubscriptionMonth.state =
          preAgency.contractInfo.offers.cancelSubscriptionMonth.state;
        this.contractInfo.offers.cancelSubscriptionMonth.month =
          preAgency.contractInfo.offers.cancelSubscriptionMonth.month;
        this.contractInfo.webValidation.state =
          preAgency.contractInfo.webValidation.state;
        this.contractInfo.mailValidation.state =
          preAgency.contractInfo.mailValidation.state;
        if (preAgency.contractInfo.address2) {
          this.contractInfo.societyAddress2 = preAgency.contractInfo.address2;
        }
        if (preAgency.contractInfo.lang) {
          this.contractInfo.lang = preAgency.contractInfo.lang;
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    }

    //? COVID offers set default value (delete this when cofid offers end)
    this.contractInfo.offers.applicationFee.state = true;
    this.contractInfo.offers.applicationFee.price = 0;
    this.contractInfo.offers.cancelSubscriptionMonth.state = true;
    this.contractInfo.offers.cancelSubscriptionMonth.month = 6;
    this.contractInfo.offers.cancelSubscriptionTravel.state = true;
    this.contractInfo.offers.cancelSubscriptionTravel.number = 4;
    //?  End of COVID offers block
  },
};
</script>

<style scoped>
.error-message {
  /*border: 1px solid red;*/
  color: white;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.5);
  max-width: 90%;
  padding: 10px 10px;
  margin: 0 auto;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message svg {
  margin-right: 10px;
}

.save-button {
  display: block;
  background-color: rgb(9, 201, 111);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin: 5px auto;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  display: block;
  background-color: rgb(139, 196, 169);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin: 10px auto;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.TAValidation {
  display: block;
  margin: 10px auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}

.form-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
.offers-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
.offers-container label {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1000px) {
  .form-container {
    width: 80%;
  }
  .offers-container {
    width: 80%;
  }
}
.form-title {
  font-size: 110%;
  font-weight: bold;
}
.form-element {
  position: relative;
  margin: 20px 0;
}

.double-form-element {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.double-form-element > div {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.double-form-element > div:nth-child(1) {
  align-self: flex-start;
  padding: 0 10px 0 0;
}
.double-form-element > div:nth-child(2) {
  align-self: flex-end;
  padding: 0 0 0 10px;
}

.form-element > input,
.double-form-element > div > input {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-element > input:disabled,
.double-form-element > div > input:disabled {
  background-color: #e5e5e5;
}
.form-element > input:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.form-element > span {
  display: block;
}
.form-element > label,
.label,
.double-form-element > div > label {
  display: block;
  font-weight: bold;
  color: #666666;
}
.form-list {
  display: inline-block;
  padding: 0;
  margin: 5px 0;
}
.form-list::after {
  clear: both;
  content: "";
  display: table;
}
.form-list > li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
/* Custom Radio Button */
.form-list input[type="radio"],
.form-list input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.form-list input[type="radio"] + label,
.form-list input[type="checkbox"] + label {
  padding-left: 24px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after {
  border-radius: 50%;
}
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.form-list input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="radio"]:focus + label::before,
.form-list input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::after {
  display: block;
}
.form-list input[type="radio"]:checked + label::before,
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::before,
.form-list input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
.offers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.offer {
  width: 25%;
  border: 1px solid #cfd9db;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  margin-bottom: 5px;
}
@media (max-width: 800px) {
  .offers {
    flex-direction: column;
    align-items: center;
  }
  .offer {
    width: 75%;
    padding: 5px;
    margin: 5px 0;
  }
}
.offer label {
  font-weight: bold;
}
#cancelSubscriptionTravelInput {
  width: 20%;
}
#applicationFee:checked + label,
#cancelSubscriptionTravel:checked + label,
#cancelSubscription:checked + label,
#cancelSubscriptionMonth:checked + label {
  font-size: 102%;
  color: #00909f;
}
</style>
